import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTrans from "./en";
import zhTrans from "./zh";

declare module "react-i18next" {
    interface CustomTypeOptions {
        resources: {
            en: typeof enTrans & { [T: string]: string };
            zh: typeof zhTrans & { [T: string]: string };
        };
    }
}

void i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTrans,
            },
            zh: {
                translation: zhTrans,
            },
        },
        lng: `${localStorage.getItem("i18nextLng") === "zh" ? "zh" : "en"}`,
        fallbackLng: "en", // default lang
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
