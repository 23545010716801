export default {
    invalid_token: "无效的令牌",
    User_Password_Not_Match: "用户密码不匹配",
    Create_Token_Failed: "创建令牌失败",
    token_expired: "过期令牌",
    permission_check_failed: "权限检查失败",
    permission_deny: "没有权限",

    unknown_auth_type: "不明身份验证类型",

    duplicated_user_email: "重复的用户的电子邮件",
    add_user_failed: "添加用户失败",
    user_not_found: "用户没有找到",
    user_blocked: "操作员被禁用",

    user_email_was_verified: "验证用户邮件",
    generate_email_confirm_code_failed: "生成电子邮件确认代码失败",
    send_email_failed: "发送邮件失败",
    email_confirm_id_not_match: "电子邮件确认id不匹配",
    email_confirm_no_code: "电子邮件确认无代码",
    email_confirm_code_not_match: "电子邮件确认代码不匹配",

    update_user_info_failed: "更新用户信息失败",
    user_info_no_update: "用户信息未更新",

    can_not_reset_password_before_verify_email: "验证邮箱前不能重置密码",
    reset_password_confirm_no_code: "重置密码,确认无效",
    reset_password_code_not_match: "重置密码不匹配",

    export_user_data_too_frequency: "过于频繁的导出用户数据",

    mark_user_to_delete_failed: "标记用户删除失败",
    user_not_founded: "用户未找到",

    cache_get_failed: "缓存获取失败",
    cache_decode_failed: "缓存解码失败",
    cache_set_failed: "缓存设置失败",
    cache_encode_failed: "缓存编码失败",
    cache_not_found: "缓存中没有找到",
    cache_delete: "缓存删除",
    cache_lock_failed: "缓存锁失效",

    send_email_too_frequency: "频繁发送电子邮件",

    unsupported_authorize_response_type: "不支持授权相应类型",
    auth_app_not_found_for_given_client_id: "没有找到指定客户端id的认证应用程序",
    unsupported_grant_type: "未支持授予类型",
    authorization_code_not_found: "没有找到授权代码",
    client_id_not_match: "客户端id不匹配",
    invalid_client_secret: "无效的客户的秘密",
    apply_refresh_token_failed: "应用刷新令牌失败",
    apply_access_token_failed: "应用访问令牌失败",
    check_authorization_code_failed: "检查授权码失效",

    refresh_token_not_found: "未找到刷新令牌",
    revoke_access_token_failed: "撤销访问令牌失败",

    create_oauth_user_failed: "创建用户失败",
    generate_authorize_code_failed: "生成授权代码失败",

    invalid_authorization_payload: "无效的授权有效载荷",
    invalid_access_token: "无效的存取令牌",
    access_token_not_found: "未找到访问令牌",

    user_has_been_blocked: "用户已被阻止",
    user_block_failed: "用户隐藏失败",
    user_unblock_failed: "用户开启失败",

    decode_user_failed: "解码用户失败",
    list_user_failed: "用户列表失败",

    duplicated_oauth_app_id: "重复的认证应用id",
    add_oauth_app_failed: "添加认证应用程序失败",
    oauth_app_not_found: "没有找到认证应用程序",
    delete_oauth_app_failed: "删除应用程序认证失败",
    decode_oauth_app_failed: "解码验证程序失败",
    list_oauth_app_failed: "列表认证应用程序失败",
    oauth_app_not_found_to_delete: "找不到要删除的认证应用程序",

    update_oauth_app_info_failed: "更新认证应用程序信息失败",
    oauth_app_info_no_update: "认证应用程序信息没有更新",

    decode_oauth_app_secret_failed: "解码认证应用程序秘密失败",
    list_oauth_app_secret_failed: "列表认证应用程序秘密失败",
    duplicated_oauth_app_secret_id: "重复的认证应用程序的秘密id",
    add_oauth_app_secret_failed: "添加认证应用程序秘密失败",
    delete_oauth_app_secret_failed: "删除认证应用程序秘密失败",
    oauth_app_secret_not_found: "未找到认证应用程序秘密",

    err: "失败了，请稍后重试",
};
