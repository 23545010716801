import React, { useEffect, useState } from "react";
import styles from "./settings.module.less";
import Information from "./information";
import NewSletter from "./newSletter";
import Account from "./account";
import Ownership from "./ownership";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { infoRequest } from "../../store/render";

export interface DataType {
    email: string;
    emailVerified: boolean;
    subscribeNews: boolean;
    name: {
        first: string;
        last: string;
        format: string;
    };
    birthday: string;
}

const Settings: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const renderInfo = useAppSelector(infoRequest);
    const [userInfo, setUserInfo] = React.useState<DataType>({
        email: "",
        emailVerified: false,
        subscribeNews: false,
        name: {
            first: "",
            last: "",
            format: "",
        },
        birthday: "",
    });

    const [isShow, _] = useState(false);

    useEffect(() => {
        if (renderInfo !== undefined) {
            const obj = {
                email: renderInfo.email,
                birthday: renderInfo.birthday,
                emailVerified: renderInfo.email_verified,
                subscribeNews: renderInfo.subscribe_news,
                name: renderInfo.name,
            };
            setUserInfo(obj);
            setLoading(true);
        }
    }, [renderInfo]);
    return (
        <div className={styles.settingsDiv}>
            {loading && (
                <div className={styles.settings}>
                    <div className={styles.userInfo}>
                        <div
                            className={styles.information}
                            // style={{ height: userInfo.birthday !== "" ? "200px" : "170px" }}
                        >
                            <Information userInfo={userInfo} />
                        </div>
                        <div className={styles.editUsers}>
                            <div className={styles.editUsersButton} onClick={() => history.push("/settings/edit-profile")}>
                                {t("settings.edit_profile")}
                            </div>
                        </div>
                        {isShow && (
                            <>
                                <div className={styles.newSletter}>
                                    <NewSletter userInfo={userInfo} />
                                </div>
                                <div className={styles.newSletter}>
                                    <Account />
                                </div>
                            </>
                        )}
                    </div>
                    {isShow && (
                        <div className={styles.ownership}>
                            <Ownership />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
export default Settings;
