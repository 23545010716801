export default {
    user_not_login: "用户未登录，不能访问",
    login_date: "登录过期，请重新登录",
    token_failure: "令牌失效",
    bad_request: "错误请求",
    not_found: "请求错误，未找到资源",
    no_allowance: "请求方法未允许",
    request_timeout: "请求超时",
    server_error: "服务端出错",
    network_not_implemented: "网络未实现",
    network_error: "网络错误",
    service_unavailable: "服务不可用",
    network_timeout: "网络超时",
    version_does: "网络协议不支持该请求",
    connection_error: "连接错误",
};
