import React from "react";
import { DatabaseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MyModal from "../../components/modal";
import styles from "./settings.module.less";
import { dataExportApi, deleteApi } from "../../services/user";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { removeToken } from "../../utils/auth";
import StatusCode from "../../utils/statusCode";
const Account: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [showData, setShowData] = React.useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [isModalReOpen, setIsModalReOpen] = React.useState<boolean>(false);

    // 控制Show Option
    const handleChangeData = () => {
        setShowData(false);
    };
    // 打开删除modal
    const showModal = () => {
        setIsModalOpen(true);
    };
    // 打开请求数据modal
    const showReModal = () => {
        setIsModalReOpen(true);
    };
    // 删除
    const handleDelete = () => {
        deleteApi()
            .then((response) => {
                if ((response.error as { code: number }).code === 0) {
                    void message.success(`${t("settings.delete_success")}`);
                    removeToken();
                    history.push("login");
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
        setIsModalOpen(false);
    };
    // 关闭删除modal
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // 关闭请求数据modal
    const handleReCancel = () => {
        setIsModalReOpen(false);
    };
    // 数据导出
    const handleExport = () => {
        dataExportApi()
            .then((response) => {
                if (response.error.code === 0) {
                    void message.success(`${t("settings.email_send")}`);
                    handleReCancel();
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
                handleReCancel();
            });
    };
    return (
        <>
            <div className={styles.newSletterContent}>
                <div className={styles.newSletterTitle}>{t("settings.data")}</div>
                <div className={styles.newSletterIcon} style={{ color: "#fff" }}>
                    <DatabaseOutlined />
                </div>
                <div className={styles.newSletterText}>{t("settings.here_you_can_request")}</div>
                {showData ? (
                    <div
                        className={styles.newSletterButton}
                        onClick={handleChangeData}
                        style={{
                            background: "rgb(28, 28, 30)",
                        }}
                    >
                        {t("settings.show_option")}
                    </div>
                ) : (
                    <div className={styles.accountButtons}>
                        <div className={styles.accountButton} onClick={showReModal}>
                            {" "}
                            {t("settings.data_request")}
                        </div>
                        <div className={styles.accountButton} onClick={showModal}>
                            {t("settings.delete_account")}
                        </div>
                        <div className={styles.accountButton} onClick={() => setShowData(true)}>
                            {t("settings.back")}
                        </div>
                    </div>
                )}
                <MyModal
                    title={t("settings.data_account")}
                    label={t("settings.are_you_sure")}
                    handleOk={handleDelete}
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                />
                <MyModal
                    title={t("settings.export_title")}
                    label={t("settings.export_content")}
                    handleOk={handleExport}
                    isModalOpen={isModalReOpen}
                    handleCancel={handleReCancel}
                />
            </div>
        </>
    );
};
export default Account;
