const settings = {
    // index
    user_list: "用户列表加载失败",
    error: "失败请重试",
    // Account
    data: "数据",
    data_account: "数据账户",
    here_you_can_request: "在这里，您可以请求您可以请求我们导出您的原始数据，或者永久删除您的用户帐户。",
    are_you_sure: "您确定要永久删除您的帐户吗?请注意，这是一个不可逆的过程。",
    show_option: "显示选择",
    data_request: "数据请求",
    cancel: "取消",
    delete_account: " 删除账户",
    delete_success: "删除成功",
    delete_err: "删除失败",
    back: "返回",
    email_send: "数据正在导出，请稍后邮件内查看",
    export_title: "导出数据",
    export_content: "您将要导出数据，数据汇总完成后将发送到您的邮件中，请注意查收。导出时间间隔为一个小时。",
    // Information
    email: "邮件",
    password: "密码",
    birthday: "生日",
    change_password: "修改密码",
    VERIFIED: "已验证",
    UNVERIFIED: "未验证",
    email_send_success: "邮件发送成功，请注意查收",
    email_send_warning: "邮件发送太频繁，请稍后重试",
    email_send_error: "邮件发送失败，请重试",
    verified_email: "验证邮件",
    click_verified_email: "重新发送确认",
    // Newsletter
    newsletter: "时事通讯",
    you_are_subscribed: "您订阅我们的通讯，我们将通知您新的发布和偶尔的特别交易!",
    looks_like_you: "看来你还没选我们的通讯。加入我们的通讯今天收到关于我们的游戏的新闻，以及偶尔的特别优惠!",
    subscribe: "订阅",
    unsubscribe: "取消订阅",
    unsubscribe_success: "取消订阅成功",
    unsubscribe_err: "取消订阅失败",
    subscribe_success: "订阅邮件发送成功，请注意查收",
    subscribe_err: "订阅邮件发送失败",
    subscription: "我们将要给你发了一封电子邮件，其中有一个链接来确认你的电子邮件地址。请点击链接确认你的订阅。",
    un_subscription: "您将要取消订阅，取消以后将不在收到来自Aquila Club的新闻、活动消息。",

    // Ownership
    ownership: "所有权",
    link_your: "链接您的steam帐户，以看到您的游戏库和解锁所有权徽章的论坛!",
    link_your_steam_account: "链接你的Steam帐户",
    unlink: "断开",

    // EditProfile
    user_name: "用户姓名",
    edit_profile: "修改资料",
    name: "名",
    input_name: "请输入名",
    user: "姓",
    input_user: "请输入姓",
    name_type: "用户姓名格式",
    input_name_type: "请选择",
    save_personal_information: "保存用户信息",
    edit_success: "编辑成功",
    edit_Err: "编辑失败",
    first_last: "<名称> <姓氏>",
    last_first: "<姓氏> <名称>",

    // reactCropper
    click_modify_avatar: "点击修改头像",
    edit_image: "裁剪你的新头像",
    Set_new_profile_picture: "设置新头像",
    img_err: "我们只支持PNG或JPG图片。",
    edit: "编辑",
};

export default settings;
