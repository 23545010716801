import React from "react";
import { TableOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./settings.module.less";
import { subscribe, unsubscribe } from "../../services/user";
import { message } from "antd";
import StatusCode from "../../utils/statusCode";
import { useAppDispatch } from "../../hooks";
import { getInfoRequest } from "../../store/render";
import MyModal from "../../components/modal";

interface Subscribe {
    subscribeNews: boolean;
}
interface propsType {
    userInfo: Subscribe;
}
const NewSletter = (props: propsType) => {
    const { subscribeNews } = props.userInfo;
    const { t } = useTranslation();
    // 订阅的弹框
    const [isModal, setIsModal] = React.useState<boolean>(false);
    // 取消订阅的弹框
    const [isUnModal, setIsUnModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    // 订阅确定
    const handleSubscription = () => {
        handleSubscribe();
        setIsModal(false);
    };
    // 订阅放弃
    const handleReCancelSubscription = () => {
        setIsModal(false);
    };
    // 取消订阅确定
    const handleUnSubscription = () => {
        handleUnsubscribe();
        setIsUnModal(false);
    };
    // 取消订阅放弃
    const handleReCancelUnSubscription = () => {
        setIsUnModal(false);
    };
    // 订阅
    const handleSubscribe = () => {
        subscribe()
            .then(async (response) => {
                if ((response.error as { code: number }).code === 0) {
                    void message.success(`${t("settings.subscribe_success")}`);
                    await dispatch(getInfoRequest());
                    setIsModal(false);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
                setIsModal(false);
            });
    };
    // 取消订阅
    const handleUnsubscribe = () => {
        void unsubscribe()
            .then(async (response) => {
                if ((response.error as { code: number }).code === 0) {
                    void message.success(`${t("settings.unsubscribe_success")}`);
                    await dispatch(getInfoRequest());
                    setIsUnModal(false);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
                setIsUnModal(false);
            });
    };
    return (
        <>
            <div className={styles.newSletterContent}>
                <div className={styles.newSletterTitle}> {t("settings.newsletter")}</div>
                <div className={styles.newSletterIcon} style={{ color: subscribeNews ? "rgb(52, 199, 89)" : "#fff" }}>
                    <TableOutlined />
                </div>
                {subscribeNews ? (
                    <div className={styles.newSletterText}>{t("settings.you_are_subscribed")}</div>
                ) : (
                    <div className={styles.newSletterText}>{t("settings.looks_like_you")}</div>
                )}
                {subscribeNews ? (
                    <div
                        className={styles.newSletterButton}
                        onClick={() => setIsUnModal(true)}
                        style={{
                            background: "rgb(28, 28, 30)",
                        }}
                    >
                        {t("settings.unsubscribe")}
                    </div>
                ) : (
                    <div
                        className={styles.newSletterButton}
                        onClick={() => setIsModal(true)}
                        style={{
                            background: "rgb(255, 59, 48)",
                        }}
                    >
                        {t("settings.subscribe")}
                    </div>
                )}
                {/* 取消订阅 */}
                <MyModal
                    title={t("settings.unsubscribe")}
                    label={t("settings.un_subscription")}
                    handleOk={handleUnSubscription}
                    isModalOpen={isUnModal}
                    handleCancel={handleReCancelUnSubscription}
                />
                {/* 订阅 */}
                <MyModal
                    title={t("settings.subscribe")}
                    label={t("settings.subscription")}
                    handleOk={handleSubscription}
                    isModalOpen={isModal}
                    handleCancel={handleReCancelSubscription}
                />
            </div>
        </>
    );
};
export default NewSletter;
