import React from "react";
import styles from "./index.module.less";
import { Image, message } from "antd";
import userHead from "../../assets/logo/userHead.svg";
import loginUser from "../../assets/logo/login_user.png";
import { CheckOutlined, DribbbleOutlined } from "@ant-design/icons";
import { oauthAppinfoApi, oauthAuthorizeApi } from "../../services/oauth";
import StatusCode from "../../utils/statusCode";
import { infoApi } from "../../services/user";
import { getQueryString } from "../../utils/getQueryString";
import UesrName from "../../components/userName";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface ResData {
    client_id: string;
    desc: string;
    homepage: string;
    logo: string;
    name: string;
}

interface ResAuthorizeData {
    code: string;
    state: string;
}

interface UserInfo {
    first: string;
    last: string;
    format: string;
}

function Oauth() {
    const { t, i18n } = useTranslation();
    const lolcat = useLocation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [clientId, setClientId] = React.useState<string>("");
    const [redirectUri, setRedirectUri] = React.useState<string>("");
    const [stateData, setStateData] = React.useState<string>("");
    const [userName, setUserName] = React.useState<UserInfo>({
        first: "",
        last: "",
        format: "",
    });
    const [avatar, setAvatar] = React.useState<string>("");

    const [userInfo, setUserInfo] = React.useState<ResData>({
        client_id: "",
        desc: "",
        homepage: "",
        logo: "",
        name: "",
    });
    React.useEffect(() => {
        document.title = t("accounts.zhifou_account");
    }, [lolcat.pathname, i18n.language]);
    React.useEffect(() => {
        const confirmId: string | null = getQueryString("client_id");
        const stateValue: string | null = getQueryString("state");
        const redirectUri: string | null = getQueryString("redirect_uri");
        setLoading(false);
        setClientId(confirmId !== null ? confirmId : "");
        setStateData(stateValue !== null ? stateValue : "");
        setRedirectUri(redirectUri !== null ? redirectUri : "");
        const obj = {
            client_id: confirmId as string,
        };
        oauthAppinfoApi(obj)
            .then((response) => {
                if (response.error.code === 0) {
                    setUserInfo(response.data as ResData);
                    setLoading(true);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                setLoading(true);
                StatusCode(code);
            });
        void infoApi().then((response) => {
            if (response.error.code === 0) {
                console.log(response);

                const name = (response.data as { name: { first: string; last: string; format: string } }).name;
                const avatar = (response.data as { avatar: string }).avatar;
                setUserName(name);
                setAvatar(avatar);
            }
        });
    }, []);
    const handleAuthorize = () => {
        let obj = null;
        if (stateData !== "") {
            obj = {
                response_type: "code",
                client_id: clientId,
                scope: ["basic_read"],
                state: stateData,
            };
        } else {
            obj = {
                response_type: "code",
                client_id: clientId,
                scope: ["basic_read"],
            };
        }
        console.log(obj);

        oauthAuthorizeApi(obj)
            .then((response) => {
                if (response.error.code === 0) {
                    if (response?.data !== null) {
                        const { code, state } = response.data as ResAuthorizeData;
                        if (redirectUri === "") {
                            void message.error({
                                content: "缺少 redirect_uri 参数",
                                type: "error",
                                duration: 1,
                                onClose: () => {
                                    location.href = document.referrer;
                                },
                            });
                        } else {
                            if (state === "") {
                                window.location.href = `${redirectUri}?code=${code}`;
                            } else {
                                window.location.href = `${redirectUri}?code=${code}&state=${state}`;
                            }
                        }
                    }
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
    };
    const handleBack = () => {
        if (redirectUri !== "") {
            window.open(`${redirectUri}`);
        }
    };
    const gotoHome = () => {
        if (redirectUri !== "") {
            window.open(`${redirectUri}`);
        }
    };

    return (
        <div className={styles.oauth}>
            {loading && (
                <div>
                    <div className={styles.oauthHeader}>
                        <Image preview={false} src={avatar !== "" ? avatar : loginUser} />
                        <div className={styles.circle}>
                            <div className={styles.circleIcon}>
                                <CheckOutlined />
                            </div>
                            <div className={styles.headerLiner}></div>
                        </div>
                    </div>
                    <div className={styles.oauthTtitle}>
                        authorize&nbsp;
                        <span className={styles.link} onClick={gotoHome}>
                            {userInfo.name}
                        </span>
                    </div>
                    <div className={styles.oauthContent}>
                        <div className={styles.oauthContentUser}>
                            <div className={styles.oauthContentUserImg} style={{ marginTop: "5px" }}>
                                <Image preview={false} src={userHead} />
                            </div>
                            <div className={styles.oauthContentUserWord}>
                                <p className={styles.oauthContentUserTitle}>
                                    <span className={styles.link} onClick={gotoHome}>
                                        {userInfo.name}
                                    </span>
                                </p>
                                <div className={styles.oauthContentUserText}>
                                    wants to access your
                                    <UesrName userName={userName} />
                                    account
                                </div>
                            </div>
                        </div>
                        <div className={styles.oauthContentUser} style={{ margin: "30px 0" }}>
                            <div className={styles.oauthContentUserImg}>
                                <div className={styles.oauthContentUserImgIcon}>
                                    <DribbbleOutlined />
                                </div>
                            </div>
                            <div className={styles.oauthContentUserWord}>
                                <p className={styles.oauthContentUserTitle}>Public data only</p>
                                <p className={styles.oauthContentUserText}>Limited access to your public data</p>
                                <p className={styles.oauthContentUserText2}>
                                    This application will be able to identify you ant read public information.
                                </p>
                            </div>
                        </div>
                        <div className={styles.oauthContentButton}>
                            <div className={styles.myButtons}>
                                <div className={styles.myButton} onClick={handleBack}>
                                    Cancel
                                </div>
                                <div className={styles.myButton} onClick={handleAuthorize}>
                                    Authorize
                                </div>
                            </div>
                            <p className={styles.oauthContentFoot} style={{ marginTop: "20px" }}>
                                Authorizing will redirect to
                            </p>
                            <p className={styles.oauthContentFoot}>{userInfo.homepage}</p>
                        </div>
                    </div>
                    {/* <div className={styles.oauthFoot}>learn more about oauth</div> */}
                </div>
            )}
        </div>
    );
}

export default Oauth;
