import { message } from "antd";
import styles from "./login.module.less";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loginApi, newSession } from "../../services/login";
import { getToken, setToken } from "../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { infoOpen, setInfoRequest, setOpenInfo } from "../../store/render";
import StatusCode from "../../utils/statusCode";

const Login = (): JSX.Element => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const openInfo = useAppSelector(infoOpen);

    const [errorInfo, setInfoError] = useState("");
    const [ent, setEnt] = useState(false);

    useEffect(() => {
        if (getToken() === undefined) {
            dispatch(setInfoRequest(undefined));
            dispatch(setOpenInfo(true));
        }
    }, [getToken()]);

    const onFinish = (values: { email: string; password: string }): void => {
        loginApi({
            type: "base",
            email: values.email,
            password: values.password,
        })
            .then((info) => {
                if (info.error.code === 0) {
                    const { data } = info;
                    setEnt(false);
                    setToken((data as { token: string }).token);
                    history.push("/settings");
                } else {
                    setInfoError(t("account_message_error"));
                    setEnt(true);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code, msg } = err.response.data.error;
                switch (code) {
                    case 220002:
                        setInfoError(t("account_message"));
                        setEnt(true);
                        break;
                    case 221003:
                        setInfoError(t("error_user"));
                        setEnt(true);
                        break;
                    case 221004:
                        setInfoError(t("user_blocked"));
                        setEnt(true);
                        break;
                    default:
                        setInfoError(msg);
                        setEnt(true);
                        break;
                }
            });
    };

    // 微信登录
    const goWxLogin = () => {
        const isPc = navigator.userAgent;
        const result = isPc.toLowerCase().match(/MicroMessenger/i);
        if (/Mobi|Android|iPhone/i.test(isPc) && result !== null && result[0] === "micromessenger") {
            // 获取client_id和state
            void newSession({ provider: "weixin.mp" })
                .then((info) => {
                    const { code } = info.error;
                    if (code === 0) {
                        const { data } = info;
                        // 拼接重定向的地址
                        const href = `${location.host}/#/oauth/wechat`;
                        // 判断是否是开发阶段
                        if (location.href.includes("10.10.81")) {
                            message.success("线下微信环境");
                            // 跳转到微信登录验证的界面
                            history.push("/oauth/wechat");
                            // 开发阶段走线上的登录
                            window.open(
                                `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
                                    (data as { client_id: string; state: string }).client_id
                                }&redirect_uri=https%3A%2F%2Faccounts.thetinyworld.cn%2F%23%2Fsettings&response_type=code&scope=snsapi_userinfo&state=${
                                    (data as { client_id: string; state: string }).state
                                }#wechat_redirect`
                            );
                        } else {
                            // 线上就在当前窗口打开
                            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
                                (data as { client_id: string; state: string }).client_id
                            }&redirect_uri=${encodeURIComponent("https://" + href)}&response_type=code&scope=snsapi_userinfo&state=${
                                (data as { client_id: string; state: string }).state
                            }#wechat_redirect`;
                        }
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                    history.push("/login");
                });
        } else {
            // 获取client_id和state
            void newSession({ provider: "weixin" })
                .then((info) => {
                    const { code } = info.error;
                    if (code === 0) {
                        const { data } = info;
                        // 拼接重定向的地址
                        const href = `${location.host}/#/oauth/wechat`;
                        // 判断是否是开发阶段
                        if (href.includes("localhost") || href.includes("127.0.0.1")) {
                            // 跳转到微信登录验证的界面
                            history.push("/oauth/wechat");
                            // 开发阶段走线上的登录
                            window.open(
                                `https://open.weixin.qq.com/connect/qrconnect?appid=${
                                    (data as { client_id: string; state: string }).client_id
                                }&redirect_uri=https%3A%2F%2Faccounts.thetinyworld.cn%2F%23%2Fsettings&response_type=code&scope=snsapi_login&state=${
                                    (data as { client_id: string; state: string }).state
                                }`
                            );
                        } else {
                            // 线上就在当前窗口打开
                            window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${
                                (data as { client_id: string; state: string }).client_id
                            }&redirect_uri=${encodeURIComponent("https://" + href)}&response_type=code&scope=snsapi_login&state=${
                                (data as { client_id: string; state: string }).state
                            }`;
                        }
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                    history.push("/login");
                });
        }
    };

    return (
        <div className={styles.login}>
            <div className={styles.loginBox}>
                <div className={styles.loginLeft}>
                    <div className={styles.logo}>{t("login")}</div>
                    {/* {ent && <div className={styles.error}>{errorInfo}</div>} */}
                    {/* <div className={styles.iForm}>
                        <Form name="normal_login" className="login-form" initialValues={{ remember: false }} onFinish={onFinish}>
                            <Form.Item
                                name="email"
                                getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                rules={[
                                    { required: true, message: t("please_email") },
                                    {
                                        pattern: EmailRegular(),
                                        message: t("forget.email_ruels"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("email")} className={styles.siteFormItemIcon} />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: "44px" }}
                                name="password"
                                rules={[
                                    { required: true, message: t("please_password") },
                                    {
                                        pattern: PassWordRegular(),
                                        message: t("password_verify"),
                                    },
                                ]}
                                // style={{ marginBottom: "3px" }}
                            >
                                <Input.Password type="password" placeholder={t("password")} className={styles.siteFormItemIcon} />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle hidden={true}>
                                    <Checkbox defaultChecked={false} className={styles.loginFormForgot}>
                                        {t("remember_me")}
                                    </Checkbox>
                                </Form.Item>
                                // 重置密码
                                <span
                                    className={styles.loginFormForgot}
                                    onClick={() => {
                                        history.push("/forget");
                                    }}
                                >
                                    {t("forgot_password")}
                                </span>
                            </Form.Item>

                            <Form.Item style={{ marginTop: "50px" }}>
                                <div style={{ textAlign: "center" }}>
                                    <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
                                        {t("login")}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>

                    <Button className={styles.footerBut} onClick={() => history.push("/signup")}>
                        {t("create_account")}
                    </Button> */}
                    <div className={styles.footer}>
                        {/* <div>{t("sign_in_with_Google")}</div>
                        <div>{t("sign_in_with_apple")}</div>
                        <div>{t("sign_in_with_facebook")}</div> */}

                        {/* 微信登录 */}
                        <div onClick={goWxLogin}>{t("sign_in_with_weChat")}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Login;
