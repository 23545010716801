import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { resetpwdApi } from "../../services/user";
import StatusCode from "../../utils/statusCode";

import styles from "./forget.module.less";
import { EmailRegular } from "../../utils/regularExpression";

interface ResetPwdInfo {
    email: string;
}

const Forget: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [showFlag, setshowFlag] = useState<boolean>(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 16 },
    };

    const onFinish = (values: ResetPwdInfo) => {
        console.log(values.email);
        resetpwdApi({
            email: values.email,
        })
            .then((info) => {
                const { error } = info;
                if (error.code === 0) {
                    setshowFlag(true);
                    void message.success(t("forget.sendemail_succes"));
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code } = err.response.data.error;
                StatusCode(code);
            });
    };

    const backLogin = () => {
        history.push("/login");
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.formBox}>
                        {showFlag ? (
                            <>
                                <p>{t("forget.forget_submitted_title")}</p>
                                <p>{t("forget.forget_submitted_message")}</p>
                                <Button htmlType="button" onClick={backLogin}>
                                    {t("forget.back_to_login")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <p>{t("forget.forget_title")}</p>
                                <p>{t("forget.forget_message")}</p>
                                <Form {...layout} name="nest-messages" onFinish={onFinish}>
                                    <Form.Item
                                        name={"email"}
                                        getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                        rules={[
                                            { required: true, message: t("please_email") },
                                            {
                                                pattern: EmailRegular(),
                                                message: t("forget.email_ruels"),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("forget.forget_email")} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button htmlType="button" onClick={backLogin}>
                                            {t("forget.back_to_login")}
                                        </Button>
                                        <Button type="primary" htmlType="submit" style={{ backgroundColor: "#189A1B" }}>
                                            {t("forget.submit")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Forget;
