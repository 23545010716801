import settings from "./settings";
import login from "./login";
import signup from "./signup";
import layout from "./layout";
import modal from "./modal";
import forget from "./forget";
import changePwd from "./changePwd";
import request from "./request";
import accounts from "./accounts";
import statusCode from "./statusCode";
import oauthLogin from "./oauthLogin";
import subscribe from "./subscribe";
import steamBind from "./steamBind";
import wechat from "./wechat";

export default {
    settings,
    ...login,
    signup,
    layout,
    modal,
    forget,
    changePwd,
    request,
    accounts,
    statusCode,
    ...oauthLogin,
    subscribe,
    steamBind,
    wechat,
};
