export default {
    // forget
    forget_title: "Forgot password?",
    forget_message: "Enter the email address you used to sign up, and we will send instructions to reset your password.",
    forget_submitted_title: "Request to reset password received",
    forget_submitted_message: "Please check your inbox, and follow the link in the email to reset password.",
    forget_email: "Your email address",
    sendemail_succes: "Email sent",
    email_ruels: "Please enter a valid email address",

    // reset
    reset_title: "Enter your new password",
    reset_submitted_title: "Your password has been reset",
    confirm_id: "Please input confirmID",
    confirm_code: "Please input confirmID",
    new_password: "New Password",
    reset_succee: "reset succeeded",
    getConfirm_fail: "getConfirm fail",
    require_msg: "Please input your password!",
    password_validate_msg: "Passwords must include 4~16 characters",

    back_to_login: "Back to login",
    submit: "Submit",
    welcome: "Welcome to AQUILA",
};
