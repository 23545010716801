import { IResponse } from "../utils/base_model";
import http from "../utils/request";

// 登录
export const loginApi = async <T>(data: IAPI.Login): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/signin",
        method: "post",
        data: { ...data },
    });
};

// 注册
export const signupApi = async <T>(data: IAPI.Signup) => {
    return await http<T>({
        url: "/user/signup",
        method: "post",
        data: { ...data },
    });
};

// 退出登录
export const logoutApi = async <T>(): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/logout",
        method: "post",
        data: {},
    });
};

// 第三方登录
export const newSession = async <T>(data: IAPI.NewSession): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/oauth/startnewsession",
        method: "post",
        data: { ...data },
    });
};

// 验证登录
export const userOauthAuth = async <T>(data: IAPI.UserOauthAuth): Promise<IResponse<T>> => {
    return await http<T>({
        url: "/user/oauth/auth",
        method: "post",
        data,
    });
};
