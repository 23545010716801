import Cookies from "js-cookie";
// 定义token
const AQUILA_TOKEN = "aquila_token";
const UploadToken = "upload_token_aquila";
const AQUILA_NAME = "aquila_name";
const AQUILA_FORMAT = "aquila_format";

// 获取token
export function getToken(): string | undefined {
    return Cookies.get(AQUILA_TOKEN);
}

// 存储token
export function setToken(token: string): string | undefined {
    return Cookies.set(AQUILA_TOKEN, token);
}

// 删除token
export function removeToken(): void {
    return Cookies.remove(AQUILA_TOKEN);
}

export function getUploadToken(tid: string): string | undefined {
    return Cookies.get(UploadToken + tid);
}

export function setUploadToken(tid: string, token: string): string | undefined {
    return Cookies.set(UploadToken + tid, token);
}

export function removeUploadToken(tid: string): void {
    console.log(UploadToken + tid);
    return Cookies.remove(UploadToken + tid);
}

// 获取token
export function getCookiesName(): string | undefined {
    return Cookies.get(AQUILA_NAME);
}

// 存储token
export function setCookiesName(name: string): string | undefined {
    return Cookies.set(AQUILA_NAME, name);
}

// 删除token
export function removeCookiesName(): void {
    return Cookies.remove(AQUILA_NAME);
}

// 获取token
export function getCookiesFormat(): string | undefined {
    return Cookies.get(AQUILA_FORMAT);
}

// 存储token
export function setCookiesFormat(name: string): string | undefined {
    return Cookies.set(AQUILA_FORMAT, name);
}

// 删除token
export function removeCookiesFormat(): void {
    return Cookies.remove(AQUILA_FORMAT);
}
