import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import React from "react";

const targetProtocol = "https:";
if (import.meta.env.VITE_NODE_ENV !== "development") {
    if (window.location.protocol !== targetProtocol) {
        window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length);
    }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
