import React, { useState } from "react";
import { Form, Input, Button, message, Select, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { infoApi, updateApi } from "../../services/user";
import styles from "./editProfile.module.less";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getInfoRequest, infoRequest } from "../../store/render";
import { RcFile } from "antd/lib/upload";
import userHead from "../../assets/logo/userHead.svg";
import addImg from "../../assets/icon/edit.svg";
import ReactCropper from "./CropperModel";

export interface DataType {
    name: {
        first: string;
        last: string;
        format: string;
    };
}

const { Option } = Select;

const EditProfile = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const history = useHistory();
    const { t } = useTranslation();
    const [formatOption, setFormatOption] = React.useState([{ id: 0, label: "", value: "" }]);
    const [first, setFirst] = React.useState<string>("");
    const [last, setLast] = React.useState<string>("");
    const renderInfo = useAppSelector(infoRequest);
    const useList = [
        { id: 1, label: `${first} ${last}`, value: `{first} {last}` },
        { id: 2, label: `${first} ${last}.`, value: `{first} {last}.` },
        { id: 3, label: `${first}. ${last}`, value: `{first}. {last}` },
        { id: 4, label: `${first}${last}`, value: `{first}{last}` },
        { id: 5, label: `${first}`, value: `{first}` },
        { id: 6, label: `${last} ${first}`, value: `{last} {first}` },
        { id: 7, label: `${last}${first}`, value: `{last}{first}` },
        { id: 8, label: `${last}, ${first}`, value: `{last}. {first}` },
        { id: 9, label: `${last}`, value: `{last}` },
    ];
    // 初始化表单数据，这里只有编辑
    React.useEffect(() => {
        void infoApi().then((res) => {
            const name = (res.data as { name: { first: string; last: string; format: string } }).name;
            setFirst(name.first);
            setLast(name.last);
            form.setFieldsValue({
                firstName: name.first,
                lastName: name.last,
                format: name.format.length > 0 ? name.format : "",
            });
            // 定义option数据
            setFormatOption(useList);
        });
    }, []);
    // 切换first
    const handleFist = (first: string) => {
        setFirst(first);
    };
    // 切换last
    const handleLast = (last: string) => {
        setLast(last);
    };
    React.useEffect(() => {
        setFormatOption(useList);
    }, [first, last]);
    // 提交数据
    const onFinish = (values: { firstName: string; lastName: string; format: string }) => {
        const obj = {
            "name.first": values.firstName,
            "name.last": values.lastName,
            "name.format": values.format,
            language: "",
        };
        void updateApi(obj).then(async (response) => {
            if ((response.error as { code: number }).code === 0) {
                void message.success(`${t("settings.edit_success")}`);

                await dispatch(getInfoRequest());
                history.push("/settings");
                // setCookiesFormat(values.format);
            } else {
                void message.error(`${t("settings.edit_Err")}`);
            }
        });
    };

    const [fileInfo, setFileInfo] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [imgErr, setImgErr] = useState(false);

    const beforeUpload = (file: RcFile) => {
        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            const getFileData = (file: Blob) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    setFileInfo(reader.result as string);
                    setOpen(true);
                    setImgErr(false);
                });
                reader.readAsDataURL(file);
            };

            getFileData(file);
        } else {
            console.log(`${file.name} is not a png/jpg/jpeg file`);
            setImgErr(true);
        }
        return false;
    };

    return (
        <>
            <div className={styles.editProfile}> {t("settings.edit_profile")}</div>
            <div className={[styles.editProfileImage, styles.formTop].join(" ")}>
                <span className={styles.picture}>
                    <Upload listType="picture" showUploadList={false} beforeUpload={beforeUpload}>
                        <label>
                            <img src={addImg} alt="" />
                            <span>{t("settings.edit")}</span>
                        </label>
                        {renderInfo !== undefined && renderInfo.avatar.length > 0 ? <img src={renderInfo.avatar} /> : <img src={userHead} />}
                    </Upload>
                </span>
                <span className={styles.pictureErr}>{imgErr && t("settings.img_err")}</span>
            </div>
            <div className={styles.editProfileNvm}>
                <div className={styles.editProfileForm}>
                    <Form
                        onFinish={onFinish}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: true }}
                        layout="horizontal"
                        form={form}
                    >
                        <Form.Item label={t("settings.name")} name="firstName">
                            <Input placeholder={t("settings.input_name")} onChange={(e) => handleFist(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={t("settings.user")} name="lastName">
                            <Input placeholder={t("settings.input_user")} onChange={(e) => handleLast(e.target.value)} />
                        </Form.Item>
                        <Form.Item label={t("settings.name_type")} name="format">
                            <Select placeholder={t("settings.input_name_type")}>
                                {formatOption.map((item: { id: number; label: string; value: string }) => {
                                    return (
                                        <Option key={item.id} value={item.value}>
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Button
                            type="primary"
                            style={{ background: "#fff", marginRight: "20px", color: "#333", border: "1px solid #fff", borderRadius: "15px" }}
                            onClick={() => history.go(-1)}
                        >
                            {t("settings.back")}
                        </Button>
                        <Button style={{ borderRadius: "15px" }} type="primary" htmlType="submit">
                            {t("settings.save_personal_information")}
                        </Button>
                    </Form>
                </div>
                <div className={[styles.editProfileImage, styles.formLeft].join(" ")}>
                    <span className={styles.picture}>
                        <Upload listType="picture" showUploadList={false} beforeUpload={beforeUpload}>
                            <label>
                                <img src={addImg} alt="" />
                                <span>{t("settings.edit")}</span>
                            </label>
                            {renderInfo !== undefined && renderInfo.avatar.length > 0 ? <img src={renderInfo.avatar} /> : <img src={userHead} />}
                        </Upload>
                    </span>
                    <span className={styles.pictureErr}>{imgErr && t("settings.img_err")}</span>
                </div>
            </div>
            {open && (
                <ReactCropper
                    imgURL={fileInfo}
                    open={open}
                    onCancel={function (): void {
                        setOpen(false);
                    }}
                    onOk={() => {
                        // return false;
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};
export default EditProfile;
