import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { infoApi } from "../services/user";
import StatusCode from "../utils/statusCode";
import { RootState } from "./index";

interface Links {
    type: string;
    meta: {
        [K: string]: string;
    };
    link_at: string;
}

interface Iinfo {
    name: {
        first: string; // 名
        last: string; // 姓
        format: string; // 用户姓名显示格式
    };
    email: string; // 邮件
    email_verified: boolean; // 邮件是否验证 ture 验证，false 未验证
    avatar: string; // 头像url，如果没有设置头像，则为空
    birthday: string; // 生日
    language: string; // 首选语言
    subscribe_news: boolean; // 订阅标识
    create_at: number; // 账号创建时间戳
    links: Links[]; // 保存第三账号的信息
}

interface IInfoRequestState {
    info: Iinfo | undefined;
    open: boolean;
}

const initialState: IInfoRequestState = {
    info: undefined,
    open: true,
};

export const getInfoRequest = createAsyncThunk("infoRequest", async () => {
    try {
        const info = await infoApi();
        const { data } = info;
        const obj = data as Iinfo;
        return obj;
    } catch (err) {
        const { code } = (err as { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }).response.data
            .error;
        StatusCode(code);
    }
});

const slice = createSlice({
    name: "attributeEditor",
    initialState,
    reducers: {
        setOpenInfo: (state: IInfoRequestState, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setInfoRequest: (state: IInfoRequestState, action: PayloadAction<undefined>) => {
            state.info = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getInfoRequest.fulfilled, (state: IInfoRequestState, action: PayloadAction<Iinfo | undefined>) => {
            state.info = action.payload;
        });
    },
});

export const { setOpenInfo, setInfoRequest } = slice.actions;

export const infoRequest = (state: RootState) => state.render.info;
export const infoOpen = (state: RootState) => state.render.open;

export default slice.reducer;
