import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
import userHead from "../../assets/logo/userHead.svg";
import zh from "../../assets/icon/zh-CN.svg";
import en from "../../assets/icon/en.svg";
import { useTranslation } from "react-i18next";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { getToken, removeToken, getCookiesFormat, removeCookiesFormat } from "../../utils/auth";

import { logoutApi } from "../../services/login";
// import { infoApi } from "../../services/user";
import { DownOutlined } from "@ant-design/icons";
import UesrName from "../../components/userName";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getInfoRequest, infoOpen, infoRequest, setOpenInfo } from "../../store/render";
import StatusCode from "../../utils/statusCode";

enum Language {
    English = "English",
    Chinese = "简体中文",
}

function projectLayout(Clild: React.ComponentType): React.ComponentType {
    const ProjectLayout = () => {
        const { t, i18n } = useTranslation();
        const history = useHistory();
        const lolcat = useLocation();
        const dispatch = useAppDispatch();
        const renderInfo = useAppSelector(infoRequest);
        const openInfo = useAppSelector(infoOpen);

        const hash: string = lolcat.pathname;

        const [evt, setEvt] = useState(true);

        const [text, setText] = useState(localStorage.getItem("i18nextLng") === "zh" ? Language.Chinese : Language.English);
        const [open, setOpen] = useState(false);
        const whiteList = ["/login", "/signup", "/forget", "/oauthLogin"];
        useEffect(() => {
            if (hash.includes("/reset")) return;
            if (getToken() === undefined && !whiteList.includes(hash)) {
                history.push("/login");
            } else if (getToken() !== undefined && whiteList.includes(hash)) {
                history.push("/settings");
            }
        }, [hash]);

        useEffect(() => {
            if (renderInfo !== undefined) {
                setEvt(true);
            }
        }, [renderInfo]);

        const getUserInfo = async () => {
            await dispatch(getInfoRequest());
            dispatch(setOpenInfo(false));
        };

        useEffect(() => {
            if (hash.includes("/reset")) return;
            if (getToken() === undefined) {
                setEvt(false);
            } else if (getToken() !== undefined && openInfo) {
                void getUserInfo();
            }
        }, [getToken()]);

        useEffect(() => {
            if (hash.includes("/reset")) return;
            if (getCookiesFormat() === undefined) return;
            if (getCookiesFormat() !== undefined) {
                void getUserInfo();
                removeCookiesFormat();
            }
        }, [getCookiesFormat()]);

        const logOut = () => {
            logoutApi()
                .then((info) => {
                    if (info.error.code === 0) {
                        removeToken();
                        history.push("/login");
                    } else {
                        console.log(info);
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    // void message.warning(msg);
                    StatusCode(code);
                });
        };

        const content = (
            <div className={styles.but}>
                <p
                    className={styles.userBut}
                    onClick={() => {
                        history.push("/Settings");
                    }}
                >
                    {t("layout.settings")}
                </p>
                <hr />
                <p className={styles.userBut} onClick={logOut}>
                    {t("layout.logOut")}
                </p>
            </div>
        );
        const contentTow = (
            <div className={styles.but}>
                <div
                    className={styles.userBut}
                    onClick={() => {
                        void i18n.changeLanguage("zh");
                        setText(Language.Chinese);
                        setOpen(false);
                    }}
                >
                    <span>
                        <img src={zh} />
                    </span>
                    &nbsp;&nbsp;
                    {Language.Chinese}
                </div>
                <hr />
                <div
                    className={styles.userBut}
                    onClick={() => {
                        void i18n.changeLanguage("en");
                        setText(Language.English);
                        setOpen(false);
                    }}
                >
                    <span>
                        <img src={en} />
                    </span>
                    &nbsp;&nbsp;
                    {Language.English}
                </div>
            </div>
        );
        const handleOpenChange = (newOpen: boolean) => {
            setOpen(newOpen);
        };
        React.useEffect(() => {
            document.title = t("accounts.zhifou_account");
            console.log(i18n.language);
        }, [lolcat.pathname, i18n.language]);
        return (
            <div className={styles.projectLayout}>
                <div className={styles.nav}>
                    <header>
                        <a href="#" className={styles.layoutLogo}></a>
                        <div className={styles.user}>
                            <div className={styles.userBox}>
                                {evt && (
                                    <Popover placement="bottomRight" content={content} trigger="click" color="#3b3b3b">
                                        <Button className={styles.username}>
                                            <span>{renderInfo !== undefined && <UesrName userName={renderInfo.name} />}</span>
                                            {renderInfo !== undefined && renderInfo.avatar.length > 0 ? (
                                                <span className={styles.head}>
                                                    <img src={renderInfo.avatar} />
                                                </span>
                                            ) : (
                                                <span className={styles.head}>
                                                    <img src={userHead} />
                                                </span>
                                            )}
                                            &ensp;
                                            <span>
                                                <DownOutlined />
                                            </span>
                                        </Button>
                                    </Popover>
                                )}
                                {!evt && <div></div>}
                            </div>
                            <div className={styles.userBox}>
                                <Popover
                                    placement="bottomRight"
                                    content={contentTow}
                                    trigger="click"
                                    open={open}
                                    color="#3b3b3b"
                                    onOpenChange={handleOpenChange}
                                >
                                    <Button className={styles.username}>
                                        <span>
                                            {text === Language.Chinese && (
                                                <span>
                                                    <img src={zh} />
                                                </span>
                                            )}
                                            {text === Language.English && (
                                                <span>
                                                    <img src={en} />
                                                </span>
                                            )}
                                            &nbsp;&nbsp;
                                            {text}
                                            &ensp;
                                            <DownOutlined />
                                        </span>
                                    </Button>
                                </Popover>
                            </div>
                        </div>
                    </header>
                </div>

                <div className={styles.layoutMain}>
                    <main>
                        <Clild />
                    </main>
                </div>
            </div>
        );
    };
    return withRouter(ProjectLayout);
}

export default projectLayout;
