export default {
    title: "Change Password",
    old_password: "Current password",
    new_password: "New password",
    submitted_msg: "Password changed successfully",
    require_msg1: "Please input your current password!",
    require_msg2: "Please input your new password!",
    same_pwd: "Same password",
    error_220002: "Passwords do not match",
    modify_result: "Password Updated",
    return_page: "Returning to the settings page in 3 seconds",
    back: "Back",
};
