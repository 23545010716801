import React, { useEffect } from "react";
import { userOauthAuth } from "../../services/login";
import { setToken } from "../../utils/auth";
import { useHistory } from "react-router-dom";
import StatusCode from "../../utils/statusCode";
import { Alert, Spin, message } from "antd";
import { useTranslation } from "react-i18next";

const WxLoginOauth = () => {
    const history = useHistory();
    const { t } = useTranslation();
    useEffect(() => {
        let href = window.location.href;
        console.log(href);
        let param = href.split("?")[1];
        const isPc = navigator.userAgent;
        const resultMicroMessenger = isPc.toLowerCase().match(/MicroMessenger/i);
        if (/Mobi|Android|iPhone/i.test(isPc) && resultMicroMessenger !== null && resultMicroMessenger[0] === "micromessenger") {
            const url = href;
            const questionMarkIndex = url.indexOf("?");
            const hashIndex = url.indexOf("#");
            const middleText = url.substring(questionMarkIndex + 1, hashIndex);
            let convertURL = "";
            if (href.includes("oauthInfo")) {
                convertURL = url.replace("?" + middleText, "") + "&" + middleText;
            } else {
                convertURL = url.replace("?" + middleText, "") + "?" + middleText;
            }
            console.log(convertURL);
            href = convertURL;
            param = convertURL.split("?")[1];
        }

        console.log(param);

        // 提取url的参数
        const result = new URLSearchParams(param);
        const code = result.get("code");
        let state = href.slice(href.lastIndexOf("=") + 1);
        if (state.includes("#")) {
            state = state.split("#")[0];
        }
        console.log(code);
        console.log(state);

        let oauthInfo = "";
        // 提取参数
        if (href.includes("(") && href.includes(")")) {
            oauthInfo = href.slice(href.indexOf("(") + 1, href.indexOf(")"));
        } else {
            oauthInfo = "";
        }

        if (code !== null && state !== null) {
            // 登录验证
            void userOauthAuth({ code, state })
                .then((info) => {
                    const { code } = info.error;
                    if (code === 0) {
                        const { data } = info;
                        setToken((data as { token: string }).token);
                        void message.success(t("wechat.login_succeeded"));
                        if (oauthInfo === "") {
                            setTimeout(() => {
                                history.push("/settings");
                            }, 1000);
                        } else {
                            window.location.href = `${location.origin}/#/oauthLogin/?${oauthInfo}`;
                            // history.push({pathname: "/oauthLogin", search: `${oauthInfo}`});
                        }
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                    void message.success(t("wechat.validation_failed"));
                    setTimeout(() => {
                        history.push("/login");
                    }, 1000);
                });
        } else {
            // 以下代码开发的时候需要手动验证所以注释了，线上的时候请去掉下面代码的注释
            // setTimeout(() => {
            //     message.error(t("wechat.parameter_acquisition_failed"))
            //     history.push("/login")
            // }, 1000)
        }
    }, [window.location.href]);

    return (
        <Spin tip={t("wechat.loading")} style={{ position: "static" }}>
            <Alert message={t("wechat.verifying_login")} type="info" style={{ height: "100vh" }} />
        </Spin>
    );
};

export default WxLoginOauth;
