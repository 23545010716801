import React from "react";
import Login from "../pages/login";
import Signup from "../pages/signup";
import Settings from "../pages/settings";
import EditProfile from "../pages/editProfile";
import Reset from "../pages/reset";
import Forget from "../pages/forget";
import { Route, Switch, Redirect } from "react-router-dom";
import projectLayout from "../pages/layout";
import Accounts from "../pages/accounts";
import Subscribe from "../pages/subscribe";
import ChangePwd from "../pages/changePwd";
import Oauth from "../pages/oauth";
import OauthLogin from "../pages/oauthLogin";
import WxLoginOauth from "../pages/wxLogin";

const routerlink: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/login" component={projectLayout(Login)} />
            <Route exact path="/signup" component={projectLayout(Signup)} />
            <Route exact path="/settings" component={projectLayout(Settings)} />
            <Route exact path="/settings/edit-profile" component={projectLayout(EditProfile)} />
            <Route exact path="/reset" component={projectLayout(Reset)} />
            <Route exact path="/forget" component={projectLayout(Forget)} />
            <Route exact path="/accounts" component={projectLayout(Accounts)} />
            <Route exact path="/subscribe" component={projectLayout(Subscribe)} />
            <Route exact path="/changePwd" component={projectLayout(ChangePwd)} />
            <Route exact path="/oauth" component={Oauth} />
            <Route exact path="/oauth/wechat" component={WxLoginOauth} />
            <Route exact path="/oauthLogin" component={OauthLogin} />
            <Redirect from="/" to="/login" />
        </Switch>
    );
};

export default routerlink;
