export default {
    first_name: "First name",
    last_name: "Last name",
    please_first: "Please input your first name!",
    please_last: "Please input your last name!",
    whether_to_subscribe_to_Email: "I want to receive news from Aquila Interactive",
    privacy_policy: 'By clicking "Create Account", you hereby confirm that you have read, understood and accepted our ',
    privacy_terms: "Terms or Use",
    privacy_or: " and ",
    privacy_privacy: "Privacy Policy.",
    Account_registered: "Account has been registered.",
    registration_failed: "registration failed",
    start_here: "Start Here",
    error_email_user: "add user failed",
    user_age_not_allow: "You need to be over 17 years old to create an account",
    date_format: "The date is not formatted correctly",
    full_date: "Please enter the full date",
    day: "Day",
    month: "Month",
    year: "Year",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
};
