import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Modal } from "antd";
import styles from "./index.module.less";
import { useTranslation } from "react-i18next";
import SparkMD5 from "spark-md5";
import { commitavatarApi, getavataruploadurlApi } from "../../../services/user";
import { useAppDispatch } from "../../../hooks";
import { getInfoRequest } from "../../../store/render";
import axios from "axios";
import { getToken } from "../../../utils/auth";

interface Props {
    open: boolean;
    imgURL: string;
    onCancel: () => void;
    onOk: () => void;
}

const ReactCropper: React.FC<Props> = ({ open, imgURL, onCancel, onOk }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const token = getToken();
    const [cropperInfo, setCropperInfo] = useState<Cropper>();
    const [btn, setBtn] = useState(false);

    function dataURItoBlob(dataURI: string) {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const onBut = () => {
        if (!btn && typeof cropperInfo !== "undefined") {
            setBtn(true);
            const blobInfo = dataURItoBlob(cropperInfo.getCroppedCanvas().toDataURL());
            const file = new File([blobInfo], "avatar", { type: blobInfo.type, lastModified: Date.now() });

            const fn = async (file: File) => {
                const getFileData = (file: Blob, info: string) => {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (reader.result !== null) {
                            const filemd5 = SparkMD5.ArrayBuffer.hash(reader.result as ArrayBuffer);
                            commitavatarApi({ session: info, file_sum: filemd5 })
                                .then(() => {
                                    dispatch(getInfoRequest())
                                        .then(() => {
                                            console.log("上传成功");
                                            setBtn(false);
                                            onOk();
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        });
                                })
                                .catch((e) => console.log(e));
                        }
                    });

                    reader.readAsArrayBuffer(file);
                };
                if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                    try {
                        const info = await getavataruploadurlApi();
                        const { data } = info as { data: { upload_url: string; session: string } };
                        const url = (data as { upload_url: string }).upload_url;
                        const checkUrl = url.includes("-internal") ? url.replace("-internal", "") : url;
                        const newUrl = checkUrl.replace(/^http/, "https");
                        if (token !== undefined) {
                            await axios.put(newUrl, file, {
                                headers: {
                                    "x-aquila-token": token,
                                    "Content-Type": "image/png",
                                },
                            });
                            getFileData(file, data.session);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                } else {
                    console.log(`${file.name} is not a png/jpg/jpeg file`);
                }
            };
            void fn(file);
        }
    };

    return (
        <Modal
            width={400}
            title={t("settings.edit_image")}
            className="cropperModal"
            open={open}
            // centered
            onCancel={onCancel}
            footer={false}
            onOk={() => {
                //
            }}
        >
            <div className={styles.cropperModalMain} onClick={(e) => e.stopPropagation()}>
                <Cropper
                    // style={{ height: 235, width: 415 }}
                    // style={{ maxHeight: 700 }}
                    style={{ maxHeight: "70vh" }}
                    zoomTo={0}
                    initialAspectRatio={1 / 1}
                    preview=".img-preview"
                    src={imgURL}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropperInfo(instance);
                    }}
                    guides={true}
                    movable={true}
                    aspectRatio={1 / 1}
                    dragMode={"move"}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
            <div className={styles.cropperModalFooter}>
                <Button onClick={onBut} style={btn ? { opacity: 0.5, cursor: "no-drop" } : {}}>
                    {t("settings.Set_new_profile_picture")}
                </Button>
            </div>
        </Modal>
    );
};
export default ReactCropper;
