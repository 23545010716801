import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MyDatePicker from "../../components/myDatePicker";
import { signupApi } from "../../services/login";
import { setToken } from "../../utils/auth";
import { getQueryString } from "../../utils/getQueryString";
import { EmailRegular, PassWordRegular } from "../../utils/regularExpression";
import { validDate, verifyAge } from "../../utils/time";

import styles from "./signup.module.less";
import moment from "moment";

interface Time {
    day: string;
    month: string;
    year: string;
}

const Signup: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [errorInfo, setInfoError] = useState("");
    const [ent, setEnt] = useState(false);
    const [backRouter, setBackRouter] = useState("");
    const confirmId: string | null = getQueryString("client_id");
    const stateValue: string | null = getQueryString("state");
    const redirectUri: string | null = getQueryString("redirectUri");

    useEffect(() => {
        const returnTo: string | null = getQueryString("return_to");
        if (returnTo !== null) {
            setBackRouter(returnTo);
        }
    }, []);

    const onFinish = (values: {
        email: string;
        password: string;
        first: string;
        last: string;
        subscribe_news: boolean;
        birthday?: {
            day: string;
            month: string;
            year: string;
        };
    }): void => {
        const { birthday } = values;
        const { day = "", month = "", year = "" } = birthday as Time;

        signupApi({
            type: "base",
            email: values.email,
            password: values.password,
            "name.first": values.first,
            "name.last": values.last,
            subscribe_news: values.subscribe_news,
            language: "zh-CN",
            birthday: moment(`${year}-${month}-${day}`).format("YYYY-MM-DD"),
        })
            .then((info) => {
                if (info.error.code === 0) {
                    const { data } = info;
                    setEnt(false);
                    setToken((data as { token: string }).token);
                    if (backRouter.length > 0) {
                        if (confirmId !== null) {
                            if (stateValue !== null) {
                                history.push({
                                    pathname: "/" + backRouter,
                                    search: `client_id=${confirmId}&state=${stateValue}${redirectUri !== null ? "&redirect_uri=" + redirectUri : ""}`,
                                });
                            } else {
                                history.push({ pathname: "/" + backRouter, search: `client_id=${confirmId}` });
                            }
                        }
                    } else {
                        history.push("/settings");
                    }
                } else {
                    setInfoError(t("signup.registration_failed"));
                    setEnt(true);
                }
            })
            .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                const { code, msg } = err.response.data.error;
                switch (code) {
                    case 221001:
                        setInfoError(t("signup.Account_registered"));
                        setEnt(true);
                        break;
                    case 221002:
                        setInfoError(t("signup.error_email_user"));
                        setEnt(true);
                        break;
                    case 221007:
                        setInfoError(t("signup.user_age_not_allow"));
                        setEnt(true);
                        break;
                    default:
                        setInfoError(msg);
                        setEnt(true);
                        break;
                }
            });
    };
    // 校验日期
    const checkPrice = async (_: unknown, value: { day: string; month: string; year: string }) => {
        if (value === undefined) {
            return await Promise.reject(new Error(t("signup.full_date")));
        }
        const { day, month, year } = value;

        // 先判断是否输入完整
        if (day !== "" && month !== "" && year !== "") {
            const valid: boolean = validDate(moment(`${year}-${month}-${day}`).format("YYYY-MM-DD"));
            // 判断日期是否合法
            if (valid) {
                // 判断日期是否满足17岁
                const seventeen: boolean = verifyAge(moment(`${year}-${month}-${day}`).format("YYYY-MM-DD"));
                if (seventeen) {
                    return await Promise.resolve();
                } else {
                    return await Promise.reject(new Error(t("signup.user_age_not_allow")));
                }
            } else {
                return await Promise.reject(new Error(t("signup.date_format")));
            }
        } else {
            return await Promise.reject(new Error(t("signup.full_date")));
        }
    };
    return (
        <div className={styles.signup}>
            <div className={styles.signupBox}>
                <div
                    className={styles.back}
                    onClick={() => {
                        if (backRouter.length > 0) {
                            if (confirmId !== null) {
                                if (stateValue !== null) {
                                    history.push({ pathname: "/" + backRouter, search: `client_id=${confirmId}&state=${stateValue}` });
                                } else {
                                    history.push({ pathname: "/" + backRouter, search: `client_id=${confirmId}` });
                                }
                            }
                        } else {
                            history.push("/login");
                        }
                    }}
                >
                    <ArrowLeftOutlined />
                </div>
                <div className={styles.logo}></div>
                <div className={styles.title}>
                    <span>{t("create_account")}</span>
                </div>
                {ent && <div className={styles.error}>{errorInfo}</div>}
                <div className={styles.from}>
                    <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Form.Item
                                name="first"
                                getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                rules={[{ required: true, message: t("signup.please_first") }]}
                                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                            >
                                <Input className={styles.siteFormItemIcon} placeholder={t("signup.first_name")} />
                            </Form.Item>
                            <Form.Item
                                name="last"
                                getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                                rules={[{ required: true, message: t("signup.please_last") }]}
                                style={{ display: "inline-block", width: "calc(50% - 8px)", margin: "0 0 0 15px" }}
                            >
                                <Input className={styles.siteFormItemIcon} placeholder={t("signup.last_name")} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            name="email"
                            getValueFromEvent={(e: { target: { value: string } }) => e.target.value.trim()}
                            rules={[
                                { required: true, message: t("please_email") },
                                {
                                    pattern: EmailRegular(),
                                    message: t("forget.email_ruels"),
                                },
                            ]}
                        >
                            <Input placeholder={t("email")} className={styles.siteFormItemIcon} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: "44px" }}
                            name="password"
                            rules={[
                                { required: true, message: t("please_password") },
                                {
                                    pattern: PassWordRegular(),
                                    message: t("password_verify"),
                                },
                            ]}
                        >
                            <Input.Password type="password" placeholder={t("password")} className={styles.siteFormItemIcon} />
                        </Form.Item>
                        <div className={styles.formTitle}> {t("birthday")}: </div>
                        <Form.Item name="birthday" rules={[{ validator: checkPrice }]}>
                            <MyDatePicker />
                        </Form.Item>

                        <Form.Item name={"subscribe_news"} valuePropName="checked" initialValue={false}>
                            <Checkbox className={styles.loginFormForgot}>{t("signup.whether_to_subscribe_to_Email")}</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <div className={styles.privacyPolicy}>
                                <span>
                                    {t("signup.privacy_policy")}
                                    <a href="#" target="_blank" rel="noreferrer">
                                        {t("signup.privacy_terms")}
                                    </a>
                                    <span>{t("signup.privacy_or")}</span>
                                    <a href="#" target="_blank" rel="noreferrer">
                                        {t("signup.privacy_privacy")}
                                    </a>
                                </span>
                            </div>
                        </Form.Item>

                        <Form.Item style={{ marginTop: "50px" }}>
                            <div style={{ textAlign: "center" }}>
                                <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
                                    {t("create_account")}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};
export default Signup;
