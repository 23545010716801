export default {
    first_name: "名",
    last_name: "姓",
    please_first: "请输入您的名!",
    please_last: "请输入您的姓!",
    whether_to_subscribe_to_Email: "我想收到来自 Aquila Interactive 的消息",
    privacy_policy: "点击“创建帐户”，您在此确认您已阅读、理解并接受我们的 ",
    privacy_terms: "使用条款",
    privacy_or: " 和 ",
    privacy_privacy: "隐私政策。",
    Account_registered: "账号已注册",
    registration_failed: "注册失败",
    start_here: "登录成功",
    error_email_user: "添加用户失败",
    user_age_not_allow: "您需要超过17岁才能创建帐户",
    date_format: "日期格式不正确",
    full_date: "请输入完整日期",
    day: "日",
    month: "月",
    year: "年",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
};
