import React, { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { resetpwdconfirmApi } from "../../services/user";
import StatusCode from "../../utils/statusCode";

import styles from "./reset.module.less";
import { PassWordRegular } from "../../utils/regularExpression";

interface resetinfo {
    password: string;
}
interface GetParam {
    confirm_id: string;
    code: string;
}

const param = () => {
    const url = location.href;
    const obj: GetParam = {
        confirm_id: "",
        code: "",
    };
    if (url.includes("?")) {
        const param = url.slice(url.indexOf("?") + 1).split("&");
        for (let i = 0; i < param.length; i++) {
            const key = param[i].split("=")[0];
            if (key === "confirm_id") {
                const value = param[i].split("=")[1];
                obj.confirm_id = value;
            } else if (key === "code") {
                const value = param[i].split("=")[1];
                obj.code = value;
            }
        }
    }
    return obj;
};

const Reset: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [resultFlag, setresultFlag] = useState<boolean>(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 16 },
    };

    const onFinish = (values: resetinfo) => {
        const confirmParam = param();
        if (confirmParam !== undefined && (confirmParam.confirm_id !== "" || confirmParam.code !== "")) {
            resetpwdconfirmApi({
                confirm_id: confirmParam.confirm_id,
                code: confirmParam.code,
                password: values.password,
            })
                .then((info) => {
                    const { error } = info;
                    if (error.code === 0) {
                        setresultFlag(true);
                        void message.success(t("forget.reset_succee"));
                    }
                })
                .catch((err: { message: string; response: { tatus: number; data: { error: { msg: string; code: number } } } }) => {
                    const { code } = err.response.data.error;
                    StatusCode(code);
                });
        } else {
            void message.error(t("forget.getConfirm_fail"));
        }
    };

    const backLogin = () => {
        history.push("/login");
    };
    return (
        <>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.formBox}>
                        {resultFlag ? (
                            <>
                                <p>{t("forget.reset_submitted_title")}</p>
                                <Button htmlType="button" onClick={backLogin}>
                                    {t("forget.back_to_login")}
                                </Button>
                            </>
                        ) : (
                            <>
                                <p>{t("forget.reset_title")}</p>
                                <Form {...layout} name="nest-messages" onFinish={onFinish}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("forget.require_msg"),
                                            },
                                            {
                                                pattern: PassWordRegular(),
                                                message: t("forget.password_validate_msg"),
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder={t("forget.new_password")} />
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        <Button htmlType="button" onClick={backLogin}>
                                            {t("forget.back_to_login")}
                                        </Button>
                                        <Button type="primary" htmlType="submit" style={{ backgroundColor: "#189A1B" }}>
                                            {t("forget.submit")}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Reset;
